import { bool, node, oneOf } from 'prop-types'

import { backgroundColors } from '../../constants'

import { BackgroundWrapper, Container } from './styles'

const propTypes = {
  children: node,
  color: oneOf(backgroundColors),
  horizontal: bool,
}

const defaultProps = {
  horizontal: false,
}

const Wrapper = ({ children, color, horizontal, ...others }) => {
  return (
    <BackgroundWrapper color={color} {...others}>
      <Container>{children}</Container>
    </BackgroundWrapper>
  )
}

Wrapper.defaultProps = defaultProps
Wrapper.propTypes = propTypes

export default Wrapper
