import { array, oneOf, string } from 'prop-types'

import { InfoContainer } from '../base.styles'
import Cards from '../components/Cards'
import Description from '../components/Description'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'
import { backgroundColors } from '../constants'

import { Grid } from './styles'

const propTypes = {
  backgroundColor: oneOf(backgroundColors),
  cards: array,
  description: string,
  headingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  templateVariant: string,
  title: string,
}

const defaultProps = {
  headingTag: 'h1',
}

const Template02 = ({
  backgroundColor,
  cards,
  description,
  headingTag,
  templateVariant,
  title,
  ...others
}) => {
  backgroundColor =
    backgroundColor === 'dark' ? backgroundColors[0] : backgroundColor
  headingTag = headingTag || defaultProps.headingTag

  return (
    <Wrapper color={backgroundColor} horizontal {...others}>
      <Grid>
        <InfoContainer center>
          <Title as={headingTag}>{title}</Title>
          <Description>{description}</Description>
        </InfoContainer>
        <Cards horizontal cards={cards} />
      </Grid>
    </Wrapper>
  )
}

Template02.propTypes = propTypes
Template02.defaultProps = defaultProps

export default Template02
