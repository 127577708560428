import { bool, string } from 'prop-types'

import RichText from 'components/RichText'

const propTypes = {
  children: string,
  darkMode: bool,
}

const defaultProps = {
  darkMode: false,
}

const Title = ({ children, darkMode, ...others }) => {
  if (!children) return null

  return (
    <RichText
      variant={{ _: 'headingMdLight', '5xl': 'headingXlLight' }}
      color={darkMode ? 'neutral.0' : 'neutral.90'}
      {...others}
    >
      {children}
    </RichText>
  )
}

Title.defaultProps = defaultProps
Title.propTypes = propTypes

export default Title
