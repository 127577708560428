import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as DefaultContainer } from 'components/Container'

const backgroundColors = {
  white: 'neutral.0',
  gray: 'neutral.10',
  dark: 'neutral.90',
}

export const BackgroundWrapper = styled.section(
  ({ color, theme }) => css`
    background-color: ${selectTheme(`colors.${backgroundColors[color]}`)({
      theme,
    })};
  `,
)

export const Container = styled(DefaultContainer)`
  padding-top: ${selectTheme('spacingTop.16xl')};
  padding-bottom: ${selectTheme('spacingBottom.16xl')};

  ${media.up('5xl')} {
    padding-top: ${selectTheme('spacingTop.21xl')};
    padding-bottom: ${selectTheme('spacingBottom.21xl')};
  }
`
