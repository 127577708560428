import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { Grid as DefaultGrid } from 'components/Grid'

export const Grid = styled(DefaultGrid)`
  grid-template-areas:
    '${'info '.repeat(4)}'
    '${'cards '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas: '${'info '.repeat(4)} ${'cards '.repeat(4)}';
  }

  ${media.up('5xl')} {
    grid-template-areas: '. ${'info '.repeat(5)} .  ${'cards '.repeat(4)} . ';
  }
`
