import { oneOf } from 'prop-types'

import { variants } from './constants'
import Template01 from './Template01'
import Template02 from './Template02'
import Template03 from './Template03'

const propTypes = {
  variant: oneOf(variants),
}

const ELEMENT_VARIANTS = {
  Template01,
  Template02,
  Template03,
}

const PersonalizedService = ({ variant, ...others }) => {
  if (!variants.includes(variant)) variant = variants[0]
  const Element = ELEMENT_VARIANTS[variant]

  return <Element {...others} />
}

PersonalizedService.propTypes = propTypes

export default PersonalizedService
