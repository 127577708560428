import { bool, string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: string,
  darkMode: bool,
  smallText: bool,
}

const defaultProps = {
  darkMode: false,
  smallText: false,
}

const Title = ({ children, darkMode, smallText, ...others }) => {
  if (!children) return null

  return (
    <Typography
      variant={smallText ? 'bodyLgMedium' : 'bodyXlMedium'}
      color={darkMode ? 'neutral.0' : 'neutral.90'}
      {...others}
    >
      {children}
    </Typography>
  )
}

Title.defaultProps = defaultProps
Title.propTypes = propTypes

export default Title
