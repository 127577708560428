import { bool, string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  darkMode: bool,
}

const defaultProps = {
  darkMode: false,
}

const Description = ({ children, darkMode, ...others }) => {
  if (!children) return null

  return (
    <Typography
      variant={{ _: 'bodyMdLight', '5xl': 'bodyXlLight' }}
      color={darkMode ? 'neutral.0' : 'neutral.80'}
      {...others}
    >
      {children}
    </Typography>
  )
}

Description.defaultProps = defaultProps
Description.propTypes = propTypes

export default Description
