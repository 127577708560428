import { bool, number, object, oneOf, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import Description from './components/Description'
import Title from './components/Title'

import { Container, Content, CustomIcon, Wrapper } from './styles'

const propTypes = {
  actionHref: string,
  darkMode: bool,
  description: string,
  icon: object,
  id: string,
  index: number,
  smallCard: bool,
  title: string,
  variant: oneOf(['Template01', 'Template02']),
}

const defaultProps = {
  darkMode: false,
  variant: 'Template01',
  smallCard: false,
}

const Card = ({
  actionHref,
  darkMode,
  description,
  icon,
  id,
  index,
  smallCard,
  title,
  variant,
  ...others
}) => {
  const { triggerEvent } = useTriggerEvents()

  const handleClick = () => {
    if (!actionHref) return

    triggerEvent({
      label: `${index + 1} | ${actionHref}`,
      pageInteraction: false,
    })
  }
  const elementProps = {
    ...(actionHref ? { href: actionHref } : { as: 'div' }),
  }

  return (
    <Container hasInnerLink={!!actionHref} {...others}>
      <Wrapper
        smallCard={smallCard}
        onClick={handleClick}
        data-testid="personalized-service-card"
        variant={variant}
        {...elementProps}
      >
        {icon && <CustomIcon smallIcon={smallCard} {...icon} />}

        <Content variant={variant} smallCard={smallCard}>
          <Title darkMode={darkMode} smallText={smallCard}>
            {title}
          </Title>
          <Description darkMode={darkMode} smallText={smallCard}>
            {description}
          </Description>
        </Content>
      </Wrapper>
    </Container>
  )
}

Card.defaultProps = defaultProps
Card.propTypes = propTypes

export default Card
