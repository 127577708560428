import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import DefaultCustomIcon from 'components/CustomIcon'

export const Wrapper = styled.a(
  ({ smallCard, variant }) => css`
    display: flex;
    flex-direction: column;
    text-decoration: none;

    ${variant === 'Template02' &&
    css`
      justify-content: flex-start;
      align-items: center;
    `}

    ${variant === 'Template01' &&
    css`
      ${media.up('5xl')} {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
    `}

    ${smallCard &&
    css`
      ${media.down('5xl')} {
        flex-direction: row;
      }
    `}
  `,
)

export const Container = styled.li(
  ({ hasInnerLink }) => css`
    list-style: none;
    margin-top: 1px;
    margin-bottom: 64px;
    position: relative;
    transition: box-shadow 0.2s ease-in-out;

    ${hasInnerLink &&
    `&:hover {
        cursor: pointer;
      }
    `}
  `,
)

export const Content = styled.div(
  ({ smallCard, theme, variant }) => css`
    ${variant === 'Template01' &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      margin-left: 0;
      margin-top: 28px;
      width: 100%;

      ${media.up('5xl')} {
        max-width: 393px;
        padding: 0;
        margin-left: 25px;
        margin-top: 0;
      }

      ${smallCard &&
      css`
        ${media.down('5xl')} {
          margin-top: 0;
          margin-left: ${selectTheme('spacingX.7xl')({ theme })};
        }
      `}
    `}

    ${variant === 'Template02' &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding: 39px 0 0 0;
      text-align: center;

      ${media.up('5xl')} {
        padding: 0;
        padding-top: 32px;
      }
    `}
  `,
)

export const CustomIcon = styled(DefaultCustomIcon)(
  ({ smallIcon }) => css`
    width: 70px;
    height: 70px;

    ${smallIcon &&
    css`
      width: 30px;
      height: 30px;
      margin-top: 5px;
    `}
  `,
)
