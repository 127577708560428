import { array, bool } from 'prop-types'

import Card from './components/Card'

import { Container } from './styles'

const propTypes = {
  cards: array,
  darkMode: bool,
  horizontal: bool,
  smallCards: bool,
}

const defaultProps = {
  darkMode: false,
  horizontal: false,
  smallCards: false,
}

const Cards = ({ cards, darkMode, horizontal, smallCards }) => {
  if (!cards?.length) return null
  const variant = horizontal ? 'Template02' : 'Template01'

  return (
    <Container
      smallCard={smallCards}
      itemsLength={cards.length}
      horizontal={horizontal}
    >
      {cards?.map((card, index) => (
        <Card
          darkMode={darkMode}
          smallCard={smallCards && !horizontal}
          variant={variant}
          key={card.id}
          index={index}
          {...card}
        />
      ))}
    </Container>
  )
}

Cards.defaultProps = defaultProps
Cards.propTypes = propTypes

export default Cards
