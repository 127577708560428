import { css, styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const horizontalList = ({ itemsLength }) => css`
  display: grid;
  grid-template-columns: 1fr;
  gap: 64px;

  ${media.up('2xl')} {
    grid-template-columns: repeat(2, auto);
  }

  ${media.up('5xl')} {
    display: grid;
    grid-template-columns: ${`repeat(${itemsLength}, auto)`};
    gap: 24px;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
  }

  li {
    margin: 0;
    height: 100%;
  }
`

const verticalList = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space;
  height: 100%;

  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Container = styled.div(
  ({ horizontal, itemsLength }) => css`
    grid-area: cards;
    ${horizontal ? horizontalList({ itemsLength }) : verticalList}
  `,
)
